export const DEALERSHIP_NAME = "Memphis Coach Co";
export const LOGO_PATH = "/images/logo.png";
export const FAVICON_PATH = "/images/favicon.png";
export const TAGLINE = "Great Coaches, Low Prices, Exceptional Service.";
export const ADDRESS = " 748 Chaney Dr 748 Chaney Dr, Collierville, TN 38017";
export const PHONE_NUMBER = "(901) 853-4339";
export const EMAIL = "memphiscoachco@gmail.com";
export const COLOR_SCHEME = "";

export const BUSINESS_HOURS = [
  { day: "Monday - Friday", hours: "9AM - 5PM" },
  { day: "Saturday", hours: "10AM - 5PM" },
  { day: "Sunday", hours: "By Appointment" }
];

export const WANTS_TOP_BAR = true;
export const WANTS_FOOTER = true;

export const WANTS_FOOTER_SOCIAL = true;
export const WANTS_FACEBOOK = true;
export const WANTS_INSTAGRAM = true;
export const WANTS_TWITTER = true;

export const HAS_CARFAX = false;
export const HAS_VEHICLE_PAYMENTS = false;

export const WANTS_CARD_FOOTER = true;
export const WANTS_CARD_TOP_LABELS = true;
export const WANTS_FAVORITES = true;
export const WANTS_SOCIAL_PROOF = false;

export const WANTS_FINANCING = false;
export const WANTS_FINANCING_SECTION = false;
export const FINANCING_TITLE = "Get Pre-Qualified";
export const FINANCING_SUBTITLE = "Get started today by filling out our secure online application.";
export const FIN_CONTACT_TITLE = "Financing Application";

export const WANTS_INVENTORY_SCROLL = true;
export const INVENTORY_SCROLL_NUMBER = "10";
export const WANTS_BROWSE_BY_TYPE = true;
export const WANTS_SPECIALTY_TYPES = true;

export const WANTS_TRADE_SECTION = false;
export const WANTS_SERVICE_SECTION = false;
export const WANTS_TESTIMONIAL_SCROLL = true;

export const MAP_URL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.2811138574657!2d-89.66632302407741!3d35.0246966657594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x887fbd279e6ea0cb:0x5765e6a7c8b8d5d1!2s748 Chaney Dr, Collierville, TN 38017!5e0!3m2!1sen!2sus!4v1713389186095!5m2!1sen!2sus";
export const MAP_LINK_URL = "https://www.google.com/maps/place/748+Chaney+Dr,+Collierville,+TN+38017/@35.0246967,-89.666323,17z/data=!3m1!4b1!4m6!3m5!1s0x887fbd279e6ea0cb:0x5765e6a7c8b8d5d1!8m2!3d35.0246923!4d-89.6637481!16s/g/11c19ww8wc?entry=ttu";

export const VEHICLE_CARD_LOW_PRICE = "Low Price";
export const VEHICLE_CARD_LOW_MILES = "Low Miles";
export const VEHICLE_CARD_INSPECTED_TEXT = "Dealer Inspected";
export const VEHICLE_CARD_PRIMARY_FOOTER_TEXT = "Get it Tomorrow";
export const VEHICLE_CARD_SECONDARY_FOOTER_TEXT = "LOCAL DELIVERY";

export const DISCLAIMER = "The estimated monthly payments and estimated down payments listed on this inventory page are based on a 720 credit score and a 72 month term with $100,000 annual gross income. Actual terms may vary based on individual creditworthiness, loan amount, and length of term. Prices listed may exclude additional fees and taxes. Please contact us for more information on financing options and terms.";

export const CONTACT_FORM_TITLE = "Get in Touch";
export const CONTACT_FORM_SUBTITLE = "We would love to hear from you!";
export const CON_CONTACT_TITLE = "Contact Form";

/**
 * An array of testimonials from customers who have purchased coaches for funeral homes.
 * The testimonials highlight customer satisfaction, service quality, and the buying experience.
 */
export const TESTIMONIALS = [
  {
    name: "John Darnold",
    title: "Satisfied Customer",
    testimonial:
      "The dealership was very supportive, and the staff were courteous and well-informed. I secured an excellent deal on a new coach, and the process was smooth and efficient.",
    rating: 5,
  },
  {
    name: "Jane Smith",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is exceptional. They assisted me in selecting the ideal coach for our funeral home needs. Their honesty and transparency were especially valuable.",
    rating: 4,
  },
  {
    name: "Alice Johnson",
    title: "Long-time Customer",
    testimonial:
      "I have been dealing with this dealership for years, and they consistently provide excellent service. They offer a broad selection of coaches, always with clear communication about costs and features.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "First-time Buyer",
    testimonial:
      "As a first-time coach buyer for our funeral home, I valued the patience and guidance provided by the dealership. They ensured I comprehended every aspect of the purchase without any pressure.",
    rating: 4,
  },
  {
    name: "Charlie Brown",
    title: "Returning Customer",
    testimonial:
      "This is the third coach I’ve purchased from this dealership, and each experience has been outstanding. The staff is approachable, and their post-sales service is commendable.",
    rating: 5,
  },
  {
    name: "Donna Davis",
    title: "Satisfied Customer",
    testimonial:
      "They streamlined the process of trading in our old coach for a new one with ease. They offered a fair trade-in value and handled all the documentation efficiently.",
    rating: 5,
  },
  {
    name: "Edward Johnson",
    title: "Happy Buyer",
    testimonial:
      "The dealership was proficient in answering all our queries and provided all the necessary information. Their dedication to service and commitment to customer satisfaction is admirable.",
    rating: 4,
  },
  {
    name: "Fiona White",
    title: "First-time Buyer",
    testimonial:
      "Their selection of coaches is impressive, and the staff was very patient and knowledgeable. I appreciated not feeling hurried or pressured into making any decisions.",
    rating: 4,
  },
  {
    name: "George Thompson",
    title: "Long-time Customer",
    testimonial:
      "This dealership always delivers exceptional service. Their team is efficient and cordial, consistently meeting our needs and surpassing our expectations.",
    rating: 5,
  },
  {
    name: "Helen Martin",
    title: "Returning Customer",
    testimonial:
      "The finance department at this dealership is outstanding. They helped us secure an excellent financing deal and ensured we fully understood all terms and conditions.",
    rating: 5,
  },
];


